import React from 'react';
import {
  Box,
  chakra,
  Flex,
  HStack,
  IconButton,
  VStack,
  Button,
  CloseButton,
  useColorModeValue,
  useDisclosure,
  Image,
  Link,
} from '@chakra-ui/react';
import { HiOutlineMenu } from 'react-icons/hi';
import { CompanyLogo } from '../../images';

const Navbar = () => {
  const bg = useColorModeValue('white', 'gray.800');
  const mobileNav = useDisclosure();
  return (
    <React.Fragment>
      <chakra.header
        bg={bg}
        w="full"
        px={{
          base: 2,
          sm: 4,
        }}
        py={4}
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <chakra.a
              href="/"
              title="Company Home Page"
              display="flex"
              alignItems="center"
            >
              <Image src={CompanyLogo} alt="logo" width="150px" />
            </chakra.a>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={1}
              mr={1}
              color="brand.500"
              display={{
                base: 'none',
                md: 'inline-flex',
              }}
            >
              <Button variant="ghost">
                <Link href="#who-we-are">Who We Are</Link>
              </Button>
              <Button variant="ghost">
                <Link href="#core-values">Our Core Values</Link>
              </Button>
              <Button variant="ghost">
                <Link href="#speciality">Speciality</Link>
              </Button>
            </HStack>
            <Box
              display={{
                base: 'inline-flex',
                md: 'none',
              }}
            >
              <IconButton
                display={{
                  base: 'flex',
                  md: 'none',
                }}
                aria-label="Open menu"
                fontSize="20px"
                color="gray.800"
                _dark={{
                  color: 'inherit',
                }}
                variant="ghost"
                icon={<HiOutlineMenu />}
                onClick={mobileNav.onOpen}
              />

              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? 'flex' : 'none'}
                flexDirection="column"
                p={2}
                pb={4}
                m={2}
                bg={bg}
                spacing={3}
                rounded="sm"
                shadow="sm"
                zIndex={99}
              >
                <CloseButton
                  aria-label="Close menu"
                  onClick={mobileNav.onClose}
                />

                <Button w="full" variant="ghost">
                  <Link href="#who-we-are">Who We are</Link>
                </Button>
                <Button w="full" variant="ghost">
                  <Link href="#core-values">Our Core Values</Link>
                </Button>
                <Button w="full" variant="ghost">
                  <Link href="#speciality">Speciality</Link>
                </Button>
              </VStack>
            </Box>
          </HStack>
        </Flex>
      </chakra.header>
    </React.Fragment>
  );
};

export default Navbar;
