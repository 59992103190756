import React from 'react';
import {
  About,
  Footer,
  Hero,
  Navbar,
  Speciality,
  Values,
} from '../../components';
import { Box } from '@chakra-ui/react';

const Home = () => {
  return (
    <Box>
      <Navbar />
      <Hero />
      <About />
      <Values />
      <Speciality />
      <Footer />
    </Box>
  );
};
export default Home;
