import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Flex, Image, Stack } from '@chakra-ui/react';
import { Accesories, Exhaust, Speed } from '../../images';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';

const Speciality = () => {
  const [slideNumber, setSlideNumber] = useState(1);
  const [content, setContent] = useState({
    image: Accesories,
    speciality: 'Speed Improvement',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis euismod libero vel leo auctor, in venenatis nulla consequat. Sed commodo nunc sit amet congue aliquam.',
  });

  const handleNextSlide = () => {
    if (slideNumber !== 3) {
      setSlideNumber(slideNumber + 1);
    }
  };

  const handlePrevSlide = () => {
    if (slideNumber !== 1) {
      setSlideNumber(slideNumber - 1);
    }
  };

  useEffect(() => {
    if (slideNumber === 1) {
      setContent({
        image: Speed,
        speciality: 'Speed Improvement',
        desc: 'Speed improvement lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis euismod libero vel leo auctor, in venenatis nulla consequat. Sed commodo nunc sit amet congue aliquam.',
      });
    } else if (slideNumber === 2) {
      setContent({
        image: Accesories,
        speciality: 'Accesories',
        desc: 'Accesories lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis euismod libero vel leo auctor, in venenatis nulla consequat. Sed commodo nunc sit amet congue aliquam.',
      });
    } else if (slideNumber === 3) {
      setContent({
        image: Exhaust,
        speciality: 'Exhaust',
        desc: 'Exhaust lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis euismod libero vel leo auctor, in venenatis nulla consequat. Sed commodo nunc sit amet congue aliquam.',
      });
    }
  }, [slideNumber]);

  return (
    <Box
      as="section"
      bg="gray.800"
      py="4"
      position="relative"
      bgColor="#509FDD"
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        m={'5'}
        h="full"
        zIndex={1}
        position="relative"
        bgColor={'white'}
        py="10"
      >
        <Heading
          textAlign="center"
          letterSpacing="tight"
          fontWeight="extrabold"
          color="#029FE4"
          id="speciality"
          fontFamily="Rubik"
        >
          Our Speciality
        </Heading>

        <Box textAlign={'center'}>
          <Flex justifyContent="center" py="10">
            <Image src={content.image} alt="logo" width="150px" />
          </Flex>
          <Box>
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={'gray.600'}
              mb="6"
              fontFamily="Rubik"
            >
              {content.speciality}
            </Text>
            <Text
              fontSize="md"
              fontWeight="light"
              color="#777777"
              mt="3"
              fontFamily="Rubik"
            >
              {content.desc}
            </Text>
          </Box>
        </Box>
        <Stack
          direction="row"
          spacing={0}
          justifyContent="space-between"
          pt="10"
          px="20"
        >
          <Box
            alignItems="center"
            justifyContent="center"
            display="flex"
            color="#D2D2D2"
            onClick={() => handlePrevSlide()}
            cursor="pointer"
          >
            <BsArrowLeft size={40} />
          </Box>
          <Box
            alignItems="center"
            justifyContent="center"
            display="flex"
            color="#3D46A2"
            onClick={() => handleNextSlide()}
            cursor="pointer"
          >
            <BsArrowRight size={40} />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Speciality;
