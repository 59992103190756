import React from 'react';
import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Select,
  Image,
} from '@chakra-ui/react';
import { CompanyLogoWhite } from '../../images';

const Footer = () => {
  return (
    <Box bg={'#07477A'} color={'white'}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid columns={[1, 1, 2]} spacing="40px">
          <Stack>
            <Box mb="6">
              <Image src={CompanyLogoWhite} alt="logo" width="150px" />
            </Box>
            <Box
              bg="white"
              width={{ base: 'auto', md: 'auto', lg: '400px' }}
              height={'auto'}
              color="black"
              p="5"
              borderRadius="5"
            >
              <Select
                defaultValue="technology"
                fontWeight="bold"
                fontSize="14px"
                fontFamily='Rubik'
              >
                <option value="technology">TECHNOLOGY DEPARTMENT</option>
                <option value="marketing">MARKETING DEPARTMENT</option>
                <option value="financial">FINANCIAL DEPARTMENT</option>
              </Select>

              <Text
                fontWeight="bold"
                fontSize="14px"
                color="#25A0D8"
                mb="6"
                pt="3"
                fontFamily='Rubik'
              >
                Jl. Lembong No 8 <br />
                Kel. Braga Kec. Sumur <br />
                Bandung, Kota <br />
                Bandung, Jawa Barat
              </Text>
            </Box>
          </Stack>

          <Stack
            align={{ base: 'flex-start', md: 'flex-end', lg: 'flex-end' }}
            alignSelf="center"
          >
            <Link href={'#who-we-are'}>Who We Are</Link>
            <Link href={'#core-values'}>Our Values</Link>
            <Link href={'#speciality'}>The Perks</Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Footer;
