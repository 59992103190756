import {
  Center,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';

const Values = (props) => {
  const { title, children, icon } = props;
  return (
    <Stack direction="row" w="100%" spacing="6">
      <Center
        aria-hidden
        flexShrink={0}
        w="12"
        h="12"
        rounded="md"
        color="#747474"
      >
        {icon}
      </Center>
      <Stack>
        <Text as="h3" fontSize="xl" fontWeight="light" fontFamily="Rubik">
          {title}
        </Text>
        <Text
          pr="6"
          color={mode('gray.600', 'gray.400')}
          lineHeight="tall"
          fontWeight="light"
          fontFamily="Rubik"
        >
          {children}
        </Text>
      </Stack>
    </Stack>
  );
};

export default Values;
