import {
  Box,
  Center,
  Heading,
  Img,
  SimpleGrid,
  Stack,
  Text,
  Flex,
} from '@chakra-ui/react';
import * as React from 'react';
import { CoreImg } from '../../images';
import Values from './values';
import { HiOutlineMinus } from 'react-icons/hi';

const CoreValues = () => {
  return (
    <Box as="section" bg="#F8F8F8" pb="10">
      <Box
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px={{
          base: '6',
          md: '8',
        }}
      >
        <Heading
          textAlign="center"
          letterSpacing="tight"
          fontWeight="extrabold"
          color="#029FE4"
          id="core-values"
          fontFamily="Rubik"
        >
          Our Core Values
        </Heading>

        <Flex
          justifyContent="center"
          textAlign={{ lg: 'center', md: 'center', base: 'left' }}
        >
          <Text
            fontSize="md"
            fontWeight="light"
            color="#777777"
            mt="3"
            fontFamily="Rubik"
          >
            Ridiculus laoreet libero pretium et, sit vel elementum convallis
            fames. Sit suspendisse etiam eget egestas. Aliquet odio et lectus
            etiam sit.
            <br />
            In mauris rutrum ac ut volutpat, ornare nibh diam. Montes, vitae,
            nec amet enim.
          </Text>
        </Flex>

        <Box mt="24">
          <SimpleGrid
            columns={{
              base: 1,
              md: 2,
            }}
            spacing={{
              base: '16',
              md: '8',
            }}
          >
            <Stack spacing="12" maxW="lg">
              <Values icon={<HiOutlineMinus />} title="Dedication">
                Nam libero tempore, cum soluta nobis est eligendi optio cumque
                nihil impedit quo minus id quod maxime placeat.
              </Values>
              <Values icon={<HiOutlineMinus />} title="Intellectual Honesty">
                Itaque earum rerum hic tenetur a sapiente delectus, ut aut
                reiciendis voluptatibus maiores alias conse.
              </Values>
              <Values icon={<HiOutlineMinus />} title="Curiosity">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque.
              </Values>
            </Stack>
            <Center>
              <Img
                objectFit="cover"
                w={{ lg: 'full', md: 'full', base: 'auto' }}
                h={{ lg: 'full', md: 'full', base: 'auto' }}
                src={CoreImg}
                alt="Core Value"
              />
            </Center>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default CoreValues;
