import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  useColorModeValue,
  Stack,
  Flex,
} from '@chakra-ui/react';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';

const About = () => {
  const [slideNumber, setSlideNumber] = useState(1);
  const [content, setContent] = useState({
    title: 'Who we are',
    type: 'Technology Company',
    desc: 'Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
  });

  const handleNextSlide = () => {
    if (slideNumber !== 3) {
      setSlideNumber(slideNumber + 1);
    }
  };

  const handlePrevSlide = () => {
    if (slideNumber !== 1) {
      setSlideNumber(slideNumber - 1);
    }
  };

  useEffect(() => {
    if (slideNumber === 1) {
      setContent({
        title: 'Who we are',
        type: 'Technology Company',
        desc: 'Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
      });
    } else if (slideNumber === 2) {
      setContent({
        title: 'What we do',
        type: 'Professional Brand Management',
        desc: 'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      });
    } else if (slideNumber === 3) {
      setContent({
        title: 'How we do',
        type: 'Strategize, Design, Collaborate',
        desc: 'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse sequam nihil molestiae consequatur.',
      });
    }
  }, [slideNumber]);

  return (
    <Box>
      <Box
        display={{ base: 'none', lg: 'grid' }}
        p={{ lg: '40px', base: '15px' }}
        bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <SimpleGrid columns={[1, 1, 3]} spacing="40px">
          <Box bgColor="white" p="10" borderRadius="5" boxShadow="md">
            <Heading
              size="xl"
              fontWeight="extrabold"
              color="#029FE4"
              id="who-we-are"
              fontFamily="Rubik"
            >
              Who we are
            </Heading>
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={useColorModeValue('gray.600', 'inherit')}
              mt="6"
              fontFamily="Rubik"
            >
              Technology Company
            </Text>
            <Text
              fontSize="md"
              fontWeight="light"
              color="#777777"
              mt="6"
              fontFamily="Rubik"
            >
              Sed ut perspiciatis unde omnis iste natus sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </Text>
          </Box>
          <Box bgColor="white" p="10" borderRadius="5" boxShadow="md">
            <Heading
              size="xl"
              fontWeight="extrabold"
              color="#029FE4"
              fontFamily="Rubik"
            >
              What we do
            </Heading>
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={useColorModeValue('gray.600', 'inherit')}
              mt="6"
              fontFamily="Rubik"
            >
              Professional Brand Management
            </Text>
            <Text
              fontSize="md"
              fontWeight="light"
              color="#777777"
              mt="6"
              fontFamily="Rubik"
            >
              Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
              consectetur, adipisci velit, sed quia non numquam eius modi
              tempora incidunt ut labore et dolore magnam aliquam quaerat
              voluptatem.
            </Text>
          </Box>
          <Box bgColor="white" p="10" borderRadius="5" boxShadow="md">
            <Heading
              size="xl"
              fontWeight="extrabold"
              color="#029FE4"
              fontFamily="Rubik"
            >
              How we do
            </Heading>

            <Text
              fontSize="lg"
              fontWeight="bold"
              color={useColorModeValue('gray.600', 'inherit')}
              mt="6"
              fontFamily="Rubik"
            >
              Strategize, Design, Collaborate
            </Text>
            <Text
              fontSize="md"
              fontWeight="light"
              color="#777777"
              mt="6"
              fontFamily="Rubik"
            >
              Ut enim ad minima veniam, quis nostrum exercitationem ullam
              corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
              consequatur? Quis autem vel eum iure reprehenderit qui in ea
              voluptate velit esse sequam nihil molestiae consequatur.
            </Text>
          </Box>
        </SimpleGrid>
      </Box>

      <Box
        p={{ lg: '40px', base: '15px' }}
        bg={useColorModeValue('gray.50', 'gray.800')}
        display={{ lg: 'none', md: 'none', base: 'grid' }}
      >
        <Box bgColor="white" p="10" borderRadius="5">
          <Heading
            size="xl"
            fontWeight="extrabold"
            color="#029FE4"
            fontFamily="Rubik"
          >
            {content.title}
          </Heading>
          <Text
            fontSize="lg"
            fontWeight="bold"
            color={useColorModeValue('gray.600', 'inherit')}
            mt="6"
            fontFamily="Rubik"
          >
            {content.type}
          </Text>
          <Text
            fontSize="md"
            fontWeight="light"
            color="#777777"
            mt="6"
            fontFamily="Rubik"
          >
            {content.desc}
          </Text>
          <Stack
            direction="row"
            spacing={0}
            justifyContent="space-between"
            pt="10"
          >
            <Box
              width="50px"
              height="50px"
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <Flex>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color={useColorModeValue('gray.600', 'inherit')}
                  mt="6"
                  fontFamily="Rubik"
                >
                  {slideNumber}
                </Text>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color={useColorModeValue('gray.400', 'inherit')}
                  mt="6"
                  ml="5px"
                  fontFamily="Rubik"
                >
                  / 3
                </Text>
              </Flex>
            </Box>

            <Flex>
              <Box
                bgColor="#F1F1F1"
                width="50px"
                height="50px"
                alignItems="center"
                justifyContent="center"
                display="flex"
                onClick={() => handlePrevSlide()}
              >
                <BsArrowLeft />
              </Box>
              <Box
                bgColor="#1BA0E1"
                width="50px"
                height="50px"
                alignItems="center"
                justifyContent="center"
                display="flex"
                color="white"
                onClick={() => handleNextSlide()}
              >
                <BsArrowRight />
              </Box>
            </Flex>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
